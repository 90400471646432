import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { EventService } from '../../services/event.service';
import { BaseComponent } from '../../../base.component';

@Component({
  selector: 'app-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.scss']
})
export class WidgetsComponent extends BaseComponent {
  @Input() dataSource: any = []
  @Input() selectedTab: any;
  @Input() columnHeading: any 
  @Input() tableConfig: any;
  @Output() deleteFacilityDetail = new EventEmitter<any>();
  @Output() editFacilityDetail = new EventEmitter<any>();
  selectedRowIndex: any;
  defaultImg = 'https://img.icons8.com/?size=100&id=ZPLpfsKE1OLu&format=png&color=000000';


  constructor(serviceEvent: EventService, cd: ChangeDetectorRef){
    super(serviceEvent, cd)
  }

  onDelete(id: number) {
    this.deleteFacilityDetail.emit(id);
  }

  onEdit(data: any) {
    this.editFacilityDetail.emit(data);
  }
}