    <div *ngIf="!loader" class="markerWrapper">
        <div class="markerShape">
            <div class="markerName">
                <app-dynamic-form-builder #form [config]="markerFormConfig"></app-dynamic-form-builder>
            </div>
            <div class="shapeList">
                <h2>Sha<span>pes</span></h2>
                <div class="shapeThumbnail" >
                            <span *ngFor="let icon of icons; let i = index" [class]="'smPreview-' + (i + 1)"  (click)="selectImage(icon)"  ><img [src]="icon" alt="Sm Thumbnail" /></span>
                            <!-- <app-button-field (click)="uploadIcon()"> Upload icon </app-button-field> -->
                </div>
            </div>
        </div>
        <div class="markerPreviewWrapper">
            <div class="markerPreview">
                        <img #image [src]="imageUrl" alt="Image Preview"/>
            </div>
            <div class="markerButton">
                <app-button-field [isDisable]="loader" (click)="onSaveMarkerDetails(form?.form, image)">
                    {{ editMarkerRowData?.id ? 'Update' : 'Save' }}</app-button-field>
            </div>
        </div>
    </div>
    <div class="facilityDetails markerFacilities">
     <app-widgets [tableConfig]="tableConfig" [selectedTab]= "selectedTab" [columnHeading]="columnHeading" (deleteFacilityDetail)="onDeleteMarkerDetail($event)"
            (editFacilityDetail)="onEditMarkerDetail($event)"></app-widgets>
    </div>
