<div class="fileUpload">
    <p-fileUpload [showUploadButton]="true" [showCancelButton]="true" id="fileUpload" name="file" accept="image/*" (onSelect)="onFileSelect($event)" (onClear)="clearFileUpload()" [invalidFileSizeMessageSummary]="'File Size is too large'" [invalidFileSizeMessageDetail]="'Maximum upload size is 1MB'" [invalidFileTypeMessageSummary]="'Invalid file type'"
        [invalidFileTypeMessageDetail]="'Only images are allowed'">
    <ng-template pTemplate type="content">
    </ng-template>

    </p-fileUpload>
        <div class="card flex justify-content-center">
            <p-image [src]="imageUrl" width="60%"></p-image>
        </div>
</div>
