import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { facilityPayload } from '../facilityModels/facilities.model';
import { ApiEndpoints } from 'src/app/core/config/api-endpoints.config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FacilitiesService {
  url: any = environment.apiUrl;
  facilitiesEndPoints = ApiEndpoints.settings.Facilities;
  buildingsEndPoints = ApiEndpoints.settings.Buildings;
  FloorsEndPoints = ApiEndpoints.settings.Floors;
  http = inject(HttpClient);
  constructor() { }
  
  addFacility(payload: FormData){
   return this.http.post<facilityPayload>(this.url + this.facilitiesEndPoints.AddFacility, payload);
  }

  getFacilities(payload: string | HttpParams, headers?: any){
    const url = `${this.url}${this.facilitiesEndPoints.AllFacilities}?pagination=false&limit=25&page=1`;
    return this.http.get<any>(url, {headers});
  }

  deleteFacility(id: string) {
    const url = `${this.url}${this.facilitiesEndPoints.AllFacilities}/${id}`;
    return this.http.delete<any>(url);
  }

  updateFacility(id: string, payload: FormData) {
    const url = `${this.url}${this.facilitiesEndPoints.EditFacility}/${id}`;
    return this.http.put<any>(url, payload);
  }

  addBuilding(payload: FormData){
    return this.http.post<facilityPayload>(this.url + this.buildingsEndPoints.AllBuildings, payload);
   }
 
   getBuildings(payload: string | HttpParams, headers?: any){
     const url = `${this.url}${this.buildingsEndPoints.AllBuildings}?pagination=false&limit=25&page=1`;
     return this.http.get<any>(url, {headers});
   }
 
   deleteBuilding(id: string) {
     const url = `${this.url}${this.buildingsEndPoints.AllBuildings}/${id}`;
     return this.http.delete<any>(url);
   }
 
   updateBuilding(id: string, payload: FormData) {
     const url = `${this.url}${this.buildingsEndPoints.AllBuildings}/${id}`;
     return this.http.put<any>(url, payload);
   }

   addFloor(payload: FormData){
    return this.http.post<facilityPayload>(this.url + this.FloorsEndPoints.AllFloors, payload);
   }
 
   getFloors(payload: string | HttpParams, headers?: any){
     const url = `${this.url}${this.FloorsEndPoints.AllFloors}?pagination=false&limit=25&page=1`;
     return this.http.get<any>(url, {headers});
   }
 
   deleteFloor(id: string) {
     const url = `${this.url}${this.FloorsEndPoints.AllFloors}/${id}`;
     return this.http.delete<any>(url);
   }
 
   updateFloor(id: string, payload: FormData) {
     const url = `${this.url}${this.FloorsEndPoints.AllFloors}/${id}`;
     return this.http.put<any>(url, payload);
   }
  
}
