import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent {
  @Output() title = new EventEmitter<any>()
  constructor(private router: Router) { 
    
  }
  navItems = [{ title: 'Campus', link: '/campus', expand: false, icon: 'pi pi-flag' },
    { title: 'Devices', link: '/add-device', icon: 'ge-icon' },

  {
    title: 'Settings', expand: false, icon: 'pi pi-cog',
    children: [{ title: 'Markers', link: '/settings/markers', icon: 'shared ' }, 
    { title: 'Facilities', link: '/settings/facilities', icon: 'webstories' }, 
    { title: 'Gateways', link: '/settings/gateways', icon: 'token' }, 
    { title: 'Groups', link: '/settings/groups', icon: 'pi pi-users' },

  ],
    
  }]

}
