import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { EventService } from '../../services/event.service';

@Component({
  selector: 'app-device-penal',
  templateUrl: './device-penal.component.html',
  styleUrls: ['./device-penal.component.scss']
})
export class DevicePenalComponent {
  @ViewChildren('scrollPenal') scrollPenal!: QueryList<ElementRef>;
  @Input() devicesList: any;
  @Input() highlightDeviceItem: any;
  @Output() selectedDevice = new EventEmitter<any[]>();
  @Output() highlightSelectedDeviceItem = new EventEmitter<any[]>();
  
  navigationUrl: string = environment.navigationUrl;
  showMenu: boolean = false;
  selectedDevices: any[] = [];
  clearTimeOut!: NodeJS.Timeout;

  constructor(
    private  serviceEvent: EventService
  ) {
  }

  ngOnChanges() {
    this.selectedDevices = [...this.devicesList.flatMap((ids: any) => ids.id || ids.vehicle_id )];
    localStorage.setItem('activeDevices', JSON.stringify(this.selectedDevices));
    if (this.highlightDeviceItem) {
      this.scrollToItem(this.highlightDeviceItem._id);  
    }
  }

  onAllCheckboxChange(list: any[], isChecked: any) {
    this.selectedDevices = [];
    if (isChecked.checked) {
      this.devicesList.map((device: any) => device.isActive = true);
      this.selectedDevices = list.flatMap(ids => ids.id || ids.vehicle_id);
    }
    this.selectedDevice.emit(this.devicesList);
    localStorage.setItem('activeDevices', JSON.stringify(this.selectedDevices));
  }

  onCheckboxChange(device: any, isChecked: any) {
    if (isChecked.checked) {
      this.selectedDevices.push(device?.vehicle_id || device.id);
    } else {
      const index = this.selectedDevices.findIndex(x => x === (device?.vehicle_id || device.id));
      if (index > -1) {
        this.selectedDevices.splice(index, 1);
      }
    }
    this.selectedDevice.emit(this.devicesList);
    localStorage.setItem('activeDevices', JSON.stringify(this.selectedDevices));
  }

  scrollToItem(itemId: string) {
    const highlightedElement = this.scrollPenal.find(el => {
      return itemId == el.nativeElement.dataset.id;
    });

    if (highlightedElement) {
      highlightedElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  highlightSelected(item: any) {
    this.highlightDeviceItem = { vehicle_id: item.vehicle_id || item.id }
    if (this.clearTimeOut) {
      clearTimeout(this.clearTimeOut);
    }
    this.clearTimeOut = setTimeout(() => {
      this.highlightDeviceItem = null;
    }, 1000);
    this.highlightSelectedDeviceItem.emit(this.highlightDeviceItem);
  }

  checkAllSelectCheckBoxStates() {
    return localStorage.getItem('activeDevices') ? this.selectedDevices.length == this.devicesList.length : true;
  }


  navigateToNovaTracking(item: any) {
    const url = `${this.navigationUrl}tracking/detail/vehicle/${item?.vehicle_id}/`
    this.postAndRedirect(url);
  }

  async postAndRedirect(url: string, data?: any) {
    const token = sessionStorage.getItem('token'); // Replace with your actual token

    try {
      this.serviceEvent.loaderService.next(true);
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            // body: JSON.stringify(data)
        });

        if (response.ok) {
          this.serviceEvent.loaderService.next(false);
          location.href = url
        } else {
            console.error('Failed to post data:', response.statusText);
            this.serviceEvent.loaderService.next(false);
        }
    } catch (error) {
      this.serviceEvent.loaderService.next(false);
        // console.error('Error posting data:', error);
    }
}

getActiveIds(){
 return this.selectedDevices = JSON.parse(localStorage.getItem("activeDevices") || '')
}


iconSet = [
  { level: 80, mod: 'high', icon: 'full' },
  { level: 40, mod: 'medium', icon: 'medium' },
  { level: 1, mod: 'low', icon: 'low' },
  { level: 0, mod: 'empty', icon: 'empty' }
];

 closestIcon(value: any) {
  return this.iconSet.find(z => value >= z.level) || this.iconSet[this.iconSet.length - 1];
}

}
