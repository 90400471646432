

import { NgModule } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { MenuModule } from 'primeng/menu';
import { FormsModule } from '@angular/forms';
import { CsvImportComponent } from './ui-components/dailogs/csv-import/csv-import.component';
import { FileUploadModule } from 'primeng/fileupload';
import { WidgetsComponent } from './ui-components/widgets/widgets.component';
import { ImageModule } from 'primeng/image';
import { CommonModule } from '@angular/common';
import { ImageUploadComponent } from './ui-components/image-upload/image-upload.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DragDropModule } from 'primeng/dragdrop';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MapViewComponent } from './ui-components/map-view/map-view.component';
import { TreeModule } from 'primeng/tree';
import { FacilityTreeComponent } from './ui-components/facility-tree/facility-tree.component';
import { GroupFilterComponent } from './ui-components/group-filter/group-filter.component';
import { AccordionModule } from 'primeng/accordion';
import { DeviceListFilterComponent } from './ui-components/device-list-filter/device-list-filter.component';
import { SearchPipe } from './pipes/search.pipe';
import { DevicePenalComponent } from './ui-components/device-penal/device-penal.component';
import { GoogleMapComponent } from './ui-components/google-map/google-map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';


const imports = [
    CommonModule,
    ButtonModule, 
    TooltipModule, 
    MenuModule,
    AvatarModule,
    FormsModule,
    FileUploadModule,
    ImageModule,
    OverlayPanelModule,
    LeafletModule,
    FormsModule,
    DragDropModule,
    InputTextModule,
    ButtonModule,
    TableModule,
    TreeModule,
    AccordionModule,
    GoogleMapsModule,
    CheckboxModule,
    DropdownModule,
    MultiSelectModule,
    RadioButtonModule
]

const components = [
    CsvImportComponent, 
    WidgetsComponent, 
    ImageUploadComponent,
    MapViewComponent,
    FacilityTreeComponent,
    GroupFilterComponent,
    DeviceListFilterComponent,
    SearchPipe,
    DevicePenalComponent,
    GoogleMapComponent
]
@NgModule({
    declarations: components,

    imports: imports,

    providers: [
    ],

    exports: [...imports, ...components],
})

export class SharedModule {
}