import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent {

  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
  @ViewChild('mapContainer') mapContainer!: any;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;

  @Input() mapConfig: any;
  @Input() devices: any[] = [];
  @Input() highlightMarker: any;
  @Input() selectedTreeNode: any

  @Output() isHideTreeView = new EventEmitter<any>();
  @Output() isHideTableView = new EventEmitter<any>();
  @Output() isHideGroupView = new EventEmitter<any>();
  @Output() resetMap = new EventEmitter<any>();
  infoContent: any = '';
  markers: any[] = [];
  center!: google.maps.LatLngLiteral

  zoom = 17;
  layers: any[] = [];
  imageOverlay: any;
  googleOriginal: any;
  deviceIds: any[] = [];
  markerIcons: string = 'https://maps.gstatic.com/mapfiles/transparent.png'; // Default marker icon URL
  zoomAnimation: any;
  display!: google.maps.LatLngLiteral;
  selectedVehicleName: string = 'Vehicle1';

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['devices']) {
      this.updateMarkers();
    // }
    if (this.highlightMarker) {
      this.zoomToMarker(this.highlightMarker._id)
    }

  }

  updateMarkers(): void {
    this.markers = [];
    if(this.selectedTreeNode){
      if (!this.selectedTreeNode?.length) {
        const config = this.selectedTreeNode
        const markerConfig: any = this.createMarker({
          markerName: config?.name || config?.title || config?.label,
          _id: config?.id || config?.vehicle_id,
          infoWindow: true,
          coordinates: { lat: +(config?.gpsMessage?.lat || config?.latitude || config?.lat), lng: +(config?.gpsMessage?.lng || config?.longitude || config?.lng) },
          popupContent: config?.label
        });
        this.markers.push(markerConfig);
      }
      const svgIcon = `
       <svg fill="#FFA500 " width="500px" height="500px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <title>star-round</title>
  <path d="M0 16q0-3.232 1.28-6.208t3.392-5.12 5.12-3.392 6.208-1.28q3.264 0 6.24 1.28t5.088 3.392 3.392 5.12 1.28 6.208q0 3.264-1.28 6.208t-3.392 5.12-5.12 3.424-6.208 1.248-6.208-1.248-5.12-3.424-3.392-5.12-1.28-6.208zM4 16q0 3.264 1.6 6.048t4.384 4.352 6.016 1.6 6.016-1.6 4.384-4.352 1.6-6.048-1.6-6.016-4.384-4.352-6.016-1.632-6.016 1.632-4.384 4.352-1.6 6.016zM6.496 12.928l6.56-0.96 2.944-5.952 2.944 5.952 6.56 0.96-4.768 4.64 1.152 6.528-5.888-3.072-5.888 3.072 1.152-6.528z"></path>
  </svg>
      `;
  
      // Convert the SVG to a data URL
      const svgDataUrl = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgIcon)}`;
      if (this.selectedTreeNode && this.selectedTreeNode[0]?.level == 'facility') {
        for (let building of this.selectedTreeNode) {
          for (let config of building?.children) {
            if (config.latitude && config.longitude) {
              const markerConfig: any = this.createMarker({
                icon: {
                  url: svgDataUrl,
                  scaledSize: { width: 30, height: 30 } // Adjust the size as needed
                },
                markerName: config?.name || config?.title || config?.label,
                _id: config.id || config.vehicle_id,
                infoWindow: false,
                coordinates: { lat: +(config?.gpsMessage?.lat || config?.latitude || config?.lat), lng: +(config?.gpsMessage?.lng || config?.longitude || config?.lng) },
                popupContent: config.label
              });
              this.markers.push(markerConfig);
            }
  
          }
        }
      }
    }
    // To show devices on the google map............

    // if (this.devices?.length) {
    //   for (let config of this.devices) {

    //     if (JSON.parse(localStorage.getItem('activeDevices') || '').includes(config?.vehicle_id || config?.id)) {
    //       const markerConfig: any = this.createMarker({
    //         markerName: config.name || config.title,
    //         _id: config.vehicle_id || config.id,
    //         coordinates: { lat: +(config?.gpsMessage?.lat || config?.lat), lng: +(config?.gpsMessage?.lng || config?.lng) },
    //         popupContent: config.label
    //       });
    //       if(markerConfig.position.lat && markerConfig.position.lng){
    //         this.markers.push(markerConfig);
    //       }
    //     }
    //   }
    if (this.markers?.length) {
      this.zoomToMarkers();
    }
  }

  zoomToMarker(id: number) {
    const location = this.markers.find(loc => loc._id === id);
    if (location) {
      this.map.panTo(location.position);
      this.map.zoom = 20; // Set the desired zoom level
    }
  }

  zoomToMarkers(): void {
    const bounds = new google.maps.LatLngBounds();

    if (this.markers.length === 1) {
      // If there is only one marker, set the zoom level to 5
      const singleMarker = this.markers[0];
      this.mapContainer?.googleMap?.setCenter(singleMarker.position);
      if (this.selectedTreeNode?.level == 'building') {
        this.mapContainer.googleMap.setZoom(20);
      } else {
        this.mapContainer.googleMap.setZoom(17);
      }

    } else {
      // Extend bounds for all markers
      this.markers.forEach(marker => {
        bounds.extend(marker.position);
      });

      setTimeout(() => {
        this.mapContainer.googleMap.fitBounds(bounds);
      }, 10);
    }
  }


  markerIcon() {
    if (!window.google) return undefined;
    return {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      fillColor: "#2725C6",
      fillOpacity: 1,
      strokeWeight: 1,
      scale: 5,
      anchor: new google.maps.Point(0, 2.5),
      labelOrigin: new google.maps.Point(0, 2.5)
    };
  }

  createMarker(options: any) {
    return {
      markerName: options.markerName,
      _id: options._id,
      position: options.coordinates,
      title: options.popupContent,
      icon: options.icon || this.markerIcon(),
      draggable: options.draggable || false,
      label: options.popupContent,
      content: options.popupContent,
      infoWindow: options.infoWindow
    };
  }

  openInfoWindow(marker: MapMarker, markerData: any) {
    if (!markerData.infoWindow) return;
    const { markerName, position }: any = markerData;
    this.infoContent = this.sanitizer.bypassSecurityTrustHtml(`
      <div class="addressNotification">
        <strong>Asset Name:</strong> ${markerName}<br>
        <strong>Location:</strong> ${position.lat}, ${position.lng}
      </div>
    `);
    this.infoWindow.open(marker);
  }

  closeInfoWindow() {
    this.infoWindow.close();
  }

  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.center = event.latLng.toJSON();
  }

  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }

  ngAfterViewInit() {
    if (this.map?.googleMap) {
      this.addCustomControls(this.map.googleMap);
    }
  }

  addCustomControls(map: google.maps.Map) {
    const hideTreeViewControlDiv = document.createElement('div');
    this.HideTreeViewControl(hideTreeViewControlDiv, map);
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(hideTreeViewControlDiv);

    const resetMapControlDiv = document.createElement('div');
    this.ResetMapControl(resetMapControlDiv, map);
    map.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(resetMapControlDiv);

    const hideGroupViewControlDiv = document.createElement('div');
    this.HideGroupViewControl(hideGroupViewControlDiv, map);
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(hideGroupViewControlDiv);
  }

  HideTreeViewControl(controlDiv: HTMLElement, map: google.maps.Map) {
    const controlUI = document.createElement('div');
    controlUI.title = 'Click to hide/show tree view';
    controlDiv.appendChild(controlUI);
    const controlText = document.createElement('div');
    let icon = 'pi-chevron-left';
    controlText.innerHTML = `<div class="hideTreeView"><i class="pi ${icon}"></i></div>`;
    controlUI.appendChild(controlText);
    controlUI.addEventListener('click', () => {
      this.isHideTreeView.emit();
      icon = icon === 'pi-chevron-right' ? 'pi-chevron-left' : 'pi-chevron-right';
      controlText.innerHTML = `<div class="hideTreeView"><i class="pi ${icon}"></i></div>`;
      setTimeout(() => {
        google.maps.event.trigger(map, 'resize');
      }, 400);
    });
  }

  HideGroupViewControl(controlDiv: HTMLElement, map: google.maps.Map) {
    const controlUI = document.createElement('div');
    controlUI.title = 'Click to hide/show group view';
    controlDiv.appendChild(controlUI);
    const controlText = document.createElement('div');
    let icon = 'pi-angle-double-left';
    controlText.innerHTML = `<div class="hideGroupView"><i class="pi ${icon}"></i></div>`;
    controlUI.appendChild(controlText);
    controlUI.addEventListener('click', () => {
      this.isHideGroupView.emit();
      icon = icon === 'pi-angle-double-right' ? 'pi-angle-double-left' : 'pi-angle-double-right';
      controlText.innerHTML = `<div class="hideGroupView"><i class="pi ${icon}"></i></div>`;
      setTimeout(() => {
        google.maps.event.trigger(map, 'resize');
      }, 400);
    });
  }

  ResetMapControl(controlDiv: HTMLElement, map: google.maps.Map) {
    const controlUI = document.createElement('div');
    controlUI.className = 'leaflet-bar leaflet-control';
    controlUI.title = 'Click to reset the map';
    controlDiv.appendChild(controlUI);
    const controlText = document.createElement('div');
    controlText.innerHTML = '<div class="resetMap"><i class="pi pi-undo"></i></div>';
    controlUI.appendChild(controlText);
    controlUI.addEventListener('click', () => {
      // const ids = this.devices.flatMap(device =>  (device.vehicle_id || device.id));
      // localStorage.setItem('activeDevices', JSON.stringify(ids));

      // this.updateMarkers();
      this.resetMap.emit();
      if (this.markers?.length) {
        this.zoomToMarkers();
      }
      setTimeout(() => {
        google.maps.event.trigger(map, 'resize');
      }, 400);
    });
  }

  // In your component TypeScript file

  getMarkerLabel(marker: any, highlightMarker: any): any {
    return {
      text: 'Location: ' + marker.markerName,
      color: 'white',
      className: 'map__marker-label ' + (marker?.vehicleName || '') +
        (highlightMarker?._id === marker?._id ? ' map__marker-label-highlight' : ''),
      fontSize: '12px',
    };
  }

}
