import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { MessageService } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { ToastModule } from 'primeng/toast';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './authentication/auth.module';
import { HttpHandlerInterceptor } from './core/interceptors/http-handler.interceptor';
import { WebSocketService } from './core/service/web-socket.service';
import { AddDeviceComponent } from './feature/add-device/add-device.component';
import { DeviceModule } from './feature/add-device/device.module';
import { CampusComponent } from './feature/campus/campus.component';
import { CampusModule } from './feature/campus/campus.module';
import { FacilitiesComponent } from './feature/settings/components/facilities/facilities.component';
import { GatewaysComponent } from './feature/settings/components/gateways/gateways.component';
import { GroupsComponent } from './feature/settings/components/groups/groups.component';
import { MarkerComponent } from './feature/settings/components/marker/marker.component';
import { SettingsModule } from './feature/settings/settings.module';
import { LayoutModule } from './layout/layout.module';

const config: SocketIoConfig = { url: environment.socketUrl, options: {} };

@NgModule({
  declarations: [
    AppComponent
  ],

  imports: [
    BrowserModule,
    RouterModule,
    LayoutModule,
    AppRoutingModule,
    AuthModule,
    DeviceModule,
    SettingsModule,
    ProgressSpinnerModule, 
    ToastModule,
    RippleModule,
    BrowserAnimationsModule,
    CampusModule,
    SocketIoModule.forRoot(config),
  ],

  providers: [
    CookieService,
    HttpClientModule,
    MessageService,
    WebSocketService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpHandlerInterceptor, multi: true },
  ],

  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private injector: Injector) {

    const campusElement = createCustomElement(CampusComponent, { injector });
    customElements.define('ble-campus-view', campusElement);

    const addDeviceElement = createCustomElement(AddDeviceComponent, { injector });
    customElements.define('ble-device-view', addDeviceElement);

    const markerElement = createCustomElement(MarkerComponent, { injector });
    customElements.define('ble-marker-view', markerElement);

    const facilityElement = createCustomElement(FacilitiesComponent, { injector });
    customElements.define('ble-facility-view', facilityElement);

    const gatewayElement = createCustomElement(GatewaysComponent, { injector });
    customElements.define('ble-gateway-view', gatewayElement);

    const groupElement = createCustomElement(GroupsComponent, { injector });
    customElements.define('ble-group-view', groupElement);
  }
  ngDoBootstrap() { }
}