import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MarkerComponent } from "./components/marker/marker.component";
import { FacilitiesComponent } from "./components/facilities/facilities.component";
import { GatewaysComponent } from "./components/gateways/gateways.component";
import { GroupsComponent } from "./components/groups/groups.component";



const routes: Routes = [
    {
        path: 'markers',
        component: MarkerComponent,
    },
    {
        path: 'facilities',
        component: FacilitiesComponent,
    },
    {
        path: 'gateways',
        component: GatewaysComponent,
    },
    {
        path: 'groups',
        component: GroupsComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SettingsRoutingModule { }