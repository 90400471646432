import { ComponentFactoryResolver, ComponentRef, Directive, Input, Type, ViewContainerRef, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '../models/field.interface';
import { InputFieldComponent } from '../../ui-components/input-field/input-field.component';
import { RadioButtonFieldComponent } from '../../ui-components/radio-button-field/radio-button-field.component';
import { DropdownFieldComponent } from '../../ui-components/dropdown-field/dropdown-field.component';
import { CheckboxFieldComponent } from '../../ui-components/checkbox-field/checkbox-field.component';
import { MultiselectComponent } from '../../ui-components/multiselect/multiselect.component';

const components: {[type: string]: Type<Field>} = {
  input: InputFieldComponent,
  radio: RadioButtonFieldComponent,
  dropdown: DropdownFieldComponent,
  checkbox: CheckboxFieldComponent,
  multiSelect: MultiselectComponent
};

@Directive({
   selector: '[dynamicField]',
  exportAs: 'dynamicField'
})

export class FormFieldDirective {
  @Input() config: any;

  component!: ComponentRef<any>;

  @Input() group!: FormGroup;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) {}

  ngOnChanges() {
    if (this.component) {
      this.component.instance.config = this.config;
      this.component.instance.group = this.group;
    }
  }

  ngOnInit() {


    if (!components[this.config.type]) {
      const supportedTypes = Object.keys(components).join(', ');
      throw new Error(
        `Trying to use an unsupported type (${this.config.type}).
        Supported types: ${supportedTypes}`
      );
    }
    const component = this.resolver.resolveComponentFactory<Field>(components[this.config.type]);
    this.component = this.container.createComponent(component);
    this.component.instance.config = this.config;
    this.component.instance.group = this.group;
  }
}
