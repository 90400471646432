import { Component, OnDestroy, ViewChild } from '@angular/core';
import { allFacilityDetails, Device, facilities } from '../settings/components/facilities/facilityModels/facilityModels';
import { CampusService } from './services/campus.service';
import { DeviceSummary, TableConfig } from './models/campus.model';
import { DeviceResponse } from 'src/app/shared/ui-components/map-view/mapModels/map.model';
import { HeadersObj } from 'src/app/shared/constants/constants';
import { FacilityTreeComponent } from 'src/app/shared/ui-components/facility-tree/facility-tree.component';
import { DevicePenalComponent } from 'src/app/shared/ui-components/device-penal/device-penal.component';
import { GroupFilterComponent } from 'src/app/shared/ui-components/group-filter/group-filter.component';
import { GroupsService } from '../settings/components/groups/services/groups.service';
import { WebSocketService } from 'src/app/core/service/web-socket.service';
import * as moment from 'moment';

@Component({
  selector: 'app-campus',
  templateUrl: './campus.component.html',
  styleUrls: ['./campus.component.scss']
})
export class CampusComponent implements OnDestroy {
  @ViewChild(FacilityTreeComponent) treeView!: FacilityTreeComponent;
  @ViewChild(GroupFilterComponent) groupComp!: GroupFilterComponent;
  @ViewChild(DevicePenalComponent) deviceListComp!: DevicePenalComponent

  facilityResponse: any;
  selectedTreeNode: any;
  selectedGroupId: any;
  selectedFlorId: any;
  selectedFacilityId: any;
  selectedFloorId: any;
  selectedBuildingId: any;
  highlightMarker: any;
  highlightDeviceItem: any
  selectedItems!: any
  groupsConfigFilter: any;
  groupsConfig: any;

  selectedFloorDevices: Device[] = [];
  mainResponse: facilities[] = [];
  showList!: boolean[];
  devicesList: any = [];
  deviceFilterList: any = [];
  tableConfig: TableConfig | undefined;

  hideFacilityTree: boolean = false;
  isTableView: boolean = true;
  hideGroupTree: boolean = false;

  activeDeviceList: any = [];
  devicePenalList: any = [];

  clearTimeOut!: NodeJS.Timeout;

  constructor(private campusService: CampusService,
    private socketService: WebSocketService,
    private groupsService: GroupsService
  ) {
  }
  ngOnDestroy(): void {
    if (this.socketService) {
      this.socketService.disconnectSocket()
    }
  }

  ngOnInit() {
    this.getAllFacilitiesDetail();
    this.getDeviceDetails();
    this.getGroupsFilters();
    this.getAllDevices();
  }

  getAllFacilitiesDetail() {
    this.campusService.getFacilitiesCampus().subscribe((res: allFacilityDetails) => {
      this.modification(res.data, 'facility');
      this.facilityResponse = res.data;
      this.facilityResponse = [{
        label: "Level",
        children: this.facilityResponse
      }];
      this.mainResponse = res.data;
      this.selectedTreeNode = res.data
    })
  }

  getAllDevices() {
    const payload = {
      "facilityId": this.selectedFacilityId,
      "buildingId": this.selectedBuildingId,
      "floorId": this.selectedFloorId,
      "groupId": this.selectedGroupId,
    }

    const getLastUpdate = (asset: any): string | null => {
      const msgDateTime = moment(asset);
      
      if (!msgDateTime.isValid()) {
        return null;
      }
      
      const now = moment();
      const diff = now.diff(msgDateTime);
    
      return moment.duration(diff).humanize() + ' ago';
    };

    this.campusService.getAllDevices(payload).subscribe((res: any) => {
      this.deviceFilterList = [];
      this.devicesList = res.data;
      this.devicesList.forEach((asset: any) => {
        this.deviceFilterList.push({ "_id": asset?.id || asset?.vehicle_id, "value": asset?.name || asset?.title });
        asset.lastUpdate = asset?.msgDateTime ? getLastUpdate(asset?.msgDateTime): null;
        asset.lastAlert = asset?.eventTime ? getLastUpdate(asset?.eventTime): null; 

      });
      this.devicePenalList = this.devicesList
    })
  }


  convertToTimestamp(dateString: string): number {
    const date = new Date(dateString);
    return date.getTime();
  }

  getActiveDevices(list: any) {
    this.devicesList = [...list];
  }

  // To manipulate the response for tree view
  modification(data: any[], level: string = '') {
    data.forEach((item: any) => {
      item.label = item.title || item.label;
      item.map_type = item.type;
      delete item['type'];
      item.level = level || item.level;

      let devicesCount = 0;



      if (item?.fixedDevices?.length) {
        devicesCount += item.fixedDevices.length;
      }
      // If there are gateways, count the devices within them
      if (item.gateway?.length) {

        item.gateway.forEach((gatewayItem: any) => {
          devicesCount += gatewayItem.device.length;
        });
      }

      // Recursively process and count devices in children
      if (item.building?.length) {
        item.children = item.building.map((buildingItem: any) => ({
          ...buildingItem,
          label: buildingItem.name,
          level: 'building',
          facilityId: item.id
        }));
        delete item.building;
        this.modification(item.children, 'building');
        item.children.forEach((child: { devicesCount: any; }) => devicesCount += child.devicesCount || 0);
      }
      if (item.floor?.length) {
        item.children = item.floor.map((floorItem: any) => ({
          ...floorItem,
          label: floorItem.label,
          level: 'floor',
          map_type: item.map_type,
          buildingId: item.id,
          facilityId: item.facilityId
        }));
        delete item.floor;
        this.modification(item.children, 'floor');
        item.children.forEach((child: { devicesCount: any; }) => devicesCount += child.devicesCount || 0);
      }

      item.devicesCount = devicesCount;
    });
  }

  // Get selected level form tree
  getSelectedNode(selectedNode: any) {
    this.selectedTreeNode = null;
    if (!selectedNode) {
      this.clearGroupFilter();
      return;
    }
    if (selectedNode?.node?.label == 'Level') {
      return;
    }
    this.resetFilterIds();
    this.selectedFloorDevices = [];
    switch (selectedNode?.node?.level) {
      case 'facility':
        this.selectedFacilityId = selectedNode?.node.id;
        this.socketService.disconnectSocket()
        break;
      case 'floor':
        this.devicesList = [];
        this.selectedFloorId = selectedNode?.node.id;
        this.selectedFloorDevices = selectedNode.node?.gateway.device || [];
        this.socketService.connectSocket();
        break;
      case 'building':
        this.socketService.disconnectSocket()
        this.selectedBuildingId = selectedNode?.node.id;
        break;
    }
    this.getAllDevices();


    if (selectedNode) {
      this.selectedTreeNode = selectedNode?.node;
    } else {
      this.selectedTreeNode = this.facilityResponse;
    }
    this.getDeviceDetails();
  }

  // Get devices list when toggle enable/disable
  getFloorDevices(devices: DeviceResponse[]) {
    this.selectedFloorDevices = [];
    this.facilityResponse.forEach((facility: any) => {
      if (facility.id == devices[0].facilityId) {
        facility.children.forEach((building: any) => {
          if (building.id == devices[0].buildingId) {
            building.children.forEach((floor: any) => {
              if (floor.id == devices[0].floorId) {
                {
                  building.children.device = devices;
                  this.selectedFloorDevices = [...building.children.device];
                }
              }
            });
          }
        })
      }
    });
    this.getDeviceDetails();

  }

  resetMapView() {
    this.treeView.treeElement.selection = null
    this.treeView.selectedNode.next(null);
    this.getDeviceDetails();
    this.clearGroupFilter();
    // this.selectedTreeNode = this.facilityResponse;
  }

  getDeviceDetails() {
    const payload = {
      "facilityId": this.selectedTreeNode?.facilityId || '',
      "buildingId": this.selectedTreeNode?.buildingId || ''
    }
    this.campusService.getDeviceSummary(payload).subscribe((tableData: DeviceSummary) => {
      this.tableConfig = tableData.data
    });

  }

  getGroupsFilters() {
    this.groupsService.getGroups(HeadersObj).subscribe((groups: any) => {
      this.groupsConfig = groups.data;
    });

  }

  getSelectedGroupItem(item: any) {
    this.facilityResponse = null;
    this.selectedTreeNode = null;
    this.selectedFacilityId = null;
    this.selectedBuildingId = null;
    this.selectedFloorId = null;
    setTimeout(() => {
      this.facilityResponse = [{
        label: "Level",
        children: this.mainResponse
      }];
      this.selectedGroupId = item?.vehicle_group_id;
      // if (item.type) {
        this.onFilterItemSelection(item);
      // }
      this.getAllDevices();


    }, 0);
  }

  collectIds(devices: any[]): any[] {
    // Helper function to recursively collect ids
    const getIds = (device: any): any[] => {
      let ids = [device?.id || device?.vehicle_id];

      if (device.children && Array.isArray(device.children)) {
        device.children.forEach((child: any) => {
          ids = ids.concat(getIds(child)); // Recursively collect ids from children
        });
      }

      return ids;
    };

    // Use flatMap to apply the helper function to each device
    return devices.flatMap((device: any) => getIds(device));
  }

  onFilterItemSelection(item: any) {
    // for multi select need to replace the check here....
    if (!item) {
      this.clearGroupFilter();
      return;
    }
    this.selectedFloorDevices = [];
    this.selectedItems = item;
  
    // For single select.......
    const devices = item?.devices ?? item?.node?.children ?? (item?.node ? [item?.node] : (item ? [item] : []));
  
    let data: any = [];
    const ids = this.collectIds(devices);
    devices.flatMap((device: any) => {
      // Initialize an array to store ids
      let allIds: any = [];
  
      // Check if the device has children
      if (device.children && Array.isArray(device.children)) {
        // Add the ids of the children to the allIds array
        allIds = allIds.concat(device.children.map((child: any) => child.id));
      } else {
        allIds = [device.id];
      }
  
      return allIds;
    });
  
    // Iterate over each facility
    [...this.mainResponse].forEach((facility: any) => {
      // Filter buildings within the facility
      const filteredBuildings = facility?.children?.map((building: any) => {
        // Filter floors within the building
        const filteredFloors = building?.children?.map((floor: any) => {
          // Filter gateways within the floor
          const filteredGateways = floor?.gateway?.map((gateway: any) => {
            // Filter devices within the gateway
            const filteredDevices = gateway?.device?.filter((device: any) => ids.includes(device?.id || device?.vehicle_id));
            // Return gateway with filtered devices if any devices match
            return filteredDevices?.length > 0 ? { ...gateway, device: filteredDevices } : null;
          }).filter((gateway: any) => gateway !== null); // Remove null values
  
          // Filter fixed devices within the floor
          const filteredFixedDevices = floor?.fixedDevices?.filter((fixDevice: any) => ids.includes( fixDevice?.vehicle_id));
  
          // Return floor with filtered gateways and fixed devices if any match
          return filteredGateways?.length > 0 || filteredFixedDevices?.length > 0 ? { ...floor, gateway: filteredGateways, fixedDevices: filteredFixedDevices } : null;
        }).filter((floor: any) => floor !== null); // Remove null values
  
        // Return building with filtered floors if any floors match
        return filteredFloors?.length > 0 ? { ...building, children: filteredFloors } : null;
      }).filter((building: any) => building !== null); // Remove null values
  
      // If there are any buildings with matching floors, add the facility to data
      if (filteredBuildings?.length > 0) {
        data.push({ ...facility, children: filteredBuildings });
      }
    });
  
    this.facilityResponse = [{
      label: "Level",
      children: data
    }];
    this.selectedTreeNode = data;
  }
  
  

  clearGroupFilter() {
    this.resetFilterIds();
    this.selectedFloorDevices = [];
    this.selectedItems = null;
    if(this.treeView){
      this.treeView.selectedNodes = [];
    }
    // this.resetNodeSelection(this.treeView.treeConfig);
    this.showList = new Array(this.groupsConfig.length).fill(false);
    this.facilityResponse = [{
      label: "Level",
      children: this.mainResponse
    }];
    this.selectedTreeNode = this.mainResponse;
    this.getAllDevices();
  }

  resetNodeSelection(node: any) {
    node.forEach((node: any) => {
      node.partialSelected = false;
      node.expanded = false;
      if (node.children) {
        this.resetNodeSelection(node.children);
      }
    });
  }

  filteredDeviceItem(item: any) {
    this.highlightDeviceItem = item;
    this.highlightMarker = item;
    if (this.clearTimeOut) {
      clearTimeout(this.clearTimeOut);
    }
    this.clearTimeOut = setTimeout(() => {
      this.highlightDeviceItem = null;
    }, 1000);
  }

  resetFilterIds() {
    this.selectedFacilityId = null;
    this.selectedBuildingId = null;
    this.selectedFloorId = null;
    this.selectedGroupId = null;
    if (this.groupComp) {
      this.groupComp.selectedItem = '';
    }
  }

  highlightSelectedDeviceItem(item: any) {
    this.highlightMarker = item;
  }

}
