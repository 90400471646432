import { Component, ViewChild } from '@angular/core';
import { deviceTypeFieldConfigs } from './add-device-type-constant';
import { deviceTypePayload } from './deviceTypeModels/device-type.model';
import { DeviceTypeService } from './services/device-type.service';
import { allFacilityDetails } from '../../../settings/components/facilities/facilityModels/facilityModels';
import { WidgetsComponent } from 'src/app/shared/ui-components/widgets/widgets.component';

@Component({
  selector: 'app-add-device-type',
  templateUrl: './add-device-type.component.html',
  styleUrls: ['./add-device-type.component.scss']
})
export class AddDeviceTypeComponent {
  @ViewChild('form') form!: any;
  @ViewChild(WidgetsComponent) selectedEditRow!: WidgetsComponent;

  deviceTypeFormConfig: any[] = deviceTypeFieldConfigs;
  tableConfig: any = [];
  // columnHeading = ['Devices', 'Name', 'Actions'];
  showFormSection: boolean = false;
  editDeviceTypeRowData: any = [];

  constructor(private deviceTypeService: DeviceTypeService) {
    this.getDeviceTypeWidgetDetail();
   }

  getDeviceTypeWidgetDetail() {
    this.deviceTypeService.getDeviceTypes('').subscribe((res: any) => {
      this.createTableConfig(res.data);
    })
  }

  showForm() {
    this.showFormSection = true;
  }

  onSaveDeviceTypeDetails(formConfig: any) {
    let payload: deviceTypePayload = {
      ...formConfig?.value
    }
    if (formConfig.invalid) {
      formConfig.markAllAsTouched();
    } else {
      if (this.editDeviceTypeRowData.id) {
        this.updateDeviceTypeDetails(payload, formConfig);
      } else {
        this.addDeviceTypeDetails(payload, formConfig);
      }
    }
  }

  addDeviceTypeDetails(payload: deviceTypePayload, formConfig: any) {
    this.deviceTypeService.addDeviceType(payload).subscribe((res: any) => {
      const data = {
        item: res.data,
        name: res.data.name,
        action: true
      }
      this.tableConfig.data.unshift(data);
      // this.getDeviceTypeWidgetDetail();
      // this.tableConfig?.data.push(formConfig.value);
      formConfig.reset();
    });
  }

  onEditDeviceTypeDetail(data: any) {
    if (this.selectedEditRow.selectedRowIndex === data.id) {
      this.form?.form.reset();
      this.editDeviceTypeRowData = '';
      this.selectedEditRow.selectedRowIndex = -1;
    } else {
      this.showFormSection = true;
      this.selectedEditRow.selectedRowIndex = data.id;
      this.editDeviceTypeRowData = data;
      setTimeout(() => {
        this.form?.form.controls['name'].setValue(data.name);
      }, 0);
    }
  }

  updateDeviceTypeDetails(payload: deviceTypePayload, formConfig: any) {
    this.deviceTypeService.updateDeviceType(this.editDeviceTypeRowData.id, payload).subscribe((res: allFacilityDetails) => {
      formConfig.reset();
      let data: any  = { ...res.data }
      const config = {
        item: data,
        name: data.name,
        action: true
      }
      for(let i =0;i<this.tableConfig.data.length;i++) {
        if(data.id == this.tableConfig.data[i].item.id ) {
          this.tableConfig.data.splice(i,1,config);
        }
      }
      // this.getDeviceTypeWidgetDetail();
      this.selectedEditRow.selectedRowIndex = -1;
      this.editDeviceTypeRowData = '';
    });
  }

  onDeleteDeviceTypeDetail(id: any) {
    this.deviceTypeService.deleteDeviceType(id).subscribe(() => {
      let deleteIndex = this.tableConfig.data.findIndex((item: any) => item.item.id == id);
      if (deleteIndex > -1) {
        this.tableConfig.data.splice(deleteIndex, 1);
      }
    });
  }

  createTableConfig(list: any) {
    const headers = [
        { header: 'Device type', field: 'name' },
        { header: 'Actions', field: 'action' },

      ];
    const data: any = []      
    list.forEach( (e:any) => {
        const config = {
            item: e,
            name: e.name,
            action: true
        }
        data.push(config)  
    });
    this.tableConfig = {header: headers, data: data};
}

}

